import React, { createContext, useContext, useEffect, useState } from 'react';

const useRegionDetection = () => {
  const [whatRegion, setWhatRegion] = useState('');

  useEffect(() => {
    if (whatRegion) {
      return;
    }

    window
      .fetch('/api/location', { method: 'HEAD' })
      .then((response) => {
        if (response.redirected) {
          // redirect detected, return the region string
          const url = response.url.split('/');
          return url[url.length - 1];
        }
        // no redirect - serve global site
        return '';
      })
      .then((r) => {
        setWhatRegion(r);
      });
  }, []);

  return {
    whatRegion,
  };
};

export const RegionContext = createContext();
export const useRegion = () => useContext(RegionContext);

export const RegionProvider = ({ children }) => {
  const { whatRegion } = useRegionDetection();

  return (
    <RegionContext.Provider
      value={{
        whatRegion,
      }}
    >
      {children}
    </RegionContext.Provider>
  );
};
