import React from 'react';

import GoogleTagManagerProvider from './src/components/googletagmanager';
import { LocalStorageProvider } from './src/hooks/useLocalStorage';
import { PrivacyBannerProvider } from './src/hooks/usePrivacy';
import { RegionProvider } from './src/hooks/useRegion';

export const wrapPageElement = ({ element }) => (
  <LocalStorageProvider>
    <RegionProvider>
      <GoogleTagManagerProvider>
        <PrivacyBannerProvider>{element}</PrivacyBannerProvider>
      </GoogleTagManagerProvider>
    </RegionProvider>
  </LocalStorageProvider>
);
