import { canUseDOM } from 'exenv';
import { node } from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

export const LocalStorageContext = createContext();

const reducer = (state, action) => {
  const { type, key, value, parse } = action;

  switch (type) {
    case 'set':
      window.localStorage.setItem(key, parse ? JSON.stringify(value) : value);
      return { ...state, [key]: value };
    case 'unset':
      window.localStorage.removeItem(key);
      return { ...state, [key]: value };
    default:
      return { ...state };
  }
};

const useLocalStorage = (key, options) => {
  const { parse, fallback } = {
    // Default values:
    parse: false,
    fallback: null,
    // Passed options:
    ...options,
  };

  const initial = useMemo(() => {
    if (!canUseDOM) {
      return fallback;
    }

    const val = window.localStorage.getItem(key);
    return (parse ? JSON.parse(val) : val) || fallback;
  }, []);

  const { state, dispatch } = useContext(LocalStorageContext);
  const current = key in state ? state[key] : initial || fallback;

  const setItem = useCallback(
    (value) =>
      dispatch({
        type: 'set',
        key,
        value,
        parse,
      }),
    [key, parse, dispatch]
  );

  const removeItem = useCallback(
    () =>
      dispatch({
        type: 'unset',
        key,
        value: fallback || null,
      }),
    [key, fallback, dispatch]
  );

  return [current, setItem, removeItem];
};

export const LocalStorageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const { Provider } = LocalStorageContext;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export default useLocalStorage;

LocalStorageProvider.propTypes = {
  children: node.isRequired,
};
