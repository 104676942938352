import { useLocation } from '@reach/router';
import { canUseDOM } from 'exenv';
import React, { useEffect, useRef } from 'react';

import { usePrivacyConsent } from '../hooks/usePrivacy';

const useGoogleTagManager = (id) => {
  const allowed = usePrivacyConsent('analytics');
  const loaded = useRef(false);

  useEffect(() => {
    if (!allowed || loaded.current || !canUseDOM || !id) {
      return;
    }

    loaded.current = true;

    // Readable and eslint valid version of GTM-Script:
    // (simplified, no dataLayer name change)
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', id);

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.appendChild(script);
  }, [id, allowed, loaded]);

  const { pathname } = useLocation();
};

const GoogleTagManagerProvider = ({ children }) => {
  useGoogleTagManager(process.env.GATSBY_GA4_TAG_ID);

  return <>{children}</>;
};

export default GoogleTagManagerProvider;
