import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import useLocalStorage from './useLocalStorage';

const PRIVACY_KEY = '_privacy-consents';
const PRIVACY_GROUPS = [
  {
    key: 'analytics',
    title: 'Analytics',
    description:
      'We collect statistics in order to understand how our visitors interact with the website and how we can improve it. The collected data does not directly identify anyone.',
  },
  {
    key: 'marketing',
    title: 'Marketing',
    description:
      'We share data about your browsing behaviour with advertising and social network service providers. The service providers perform profiling of your activities and display targeted advertising on other websites.',
  },
  {
    key: 'contents',
    title: 'Contents',
    description:
      'We integrate third-party editorial content - for example, video platforms or social networks - into our website via iframes or embeds. These external services may use your data to analyse your online behaviour when you are shown corresponding content on our site. We have no influence on whether and how the third-party providers use your data.',
  },
];

export const PrivacyBannerContext = createContext();
export const usePrivacyBanner = () => useContext(PrivacyBannerContext);

export const PrivacyBannerProvider = ({ children }) => {
  const { groups } = usePrivacy();
  const needsConsent = groups.some(({ allowed }) => allowed === undefined);
  const [shouldShowPrivacyBanner, setShouldShowPrivacyBanner] = useState(needsConsent);

  return (
    <PrivacyBannerContext.Provider
      value={{
        shouldShowPrivacyBanner,
        setShouldShowPrivacyBanner,
      }}
    >
      {children}
    </PrivacyBannerContext.Provider>
  );
};

export const usePrivacy = () => {
  const [consents, save] = useLocalStorage(PRIVACY_KEY, {
    fallback: {},
    parse: true,
  });

  const groups = useMemo(
    () =>
      PRIVACY_GROUPS.map(({ key, title, description }) => ({
        key,
        title,
        description,
        allowed: consents[key],
      })),
    [consents]
  );

  const update = useCallback(
    (newConsents) => {
      if (newConsents.length !== PRIVACY_GROUPS.length) {
        throw new Error('Incorrect amount of given consents.');
      }
      const value = PRIVACY_GROUPS.reduce(
        (acc, group, index) => ({ ...acc, [group.key]: newConsents[index] }),
        {}
      );
      save(value);
    },
    [save]
  );

  return { groups, update };
};

export const usePrivacyConsent = (key) => {
  const [consents] = useLocalStorage(PRIVACY_KEY, {
    fallback: {},
    parse: true,
  });
  return consents[key] || false;
};
